import request from '@/utils/fin-request'

// 费用项目管理-分页查询
export function listFeeItemByPage(params) {
  return request({
    url: `/fin-payment/logisticsFeeItem/listFeeItemByPage`,
    method: 'get',
    params
  })
}

// 费用项目管理级联
export function feeItemOnSelect() {
  return request({
    url: `fin-payment/logisticsFeeItem/feeItemOnSelect`,
    method: 'get'
  })
}

// 费用项目管理-新增
export function add(data) {
  return request({
    url: `/fin-payment/logisticsFeeItem/add`,
    method: 'post',
    data
  })
}

// 编辑
export function update(data) {
  return request({
    url: `/fin-payment/logisticsFeeItem/update`,
    method: 'post',
    data
  })
}

// 删除
export function deleteItem(data) {
  return request({
    url: `/fin-payment/logisticsFeeItem/delete`,
    method: 'post',
    data
  })
}

// 报价方式查询-报价模板选择(级联)
export function listOfferMethodTwoLevel(params) {
  return request({
    url: `/fin-payment/logisticsReg/listOfferMethodTwoLevel`,
    method: 'get',
    params
  })
}

// 物流发运管理-查询 物流管理未出费用
export function listShipInfoByPage(params) {
  return request({
    url: `/fin-payment/logisticsShip/listShipInfoByPage`,
    method: 'get',
    params
  })
}

// 已出费用分页
export function listRegItemByPage(params) {
  return request({
    url: `/fin-payment/logisticsReg/listRegItemByPage`,
    method: 'get',
    params
  })
}

// 特殊费用登记
export function specialDetailsReg(data) {
  return request({
    url: `/fin-payment/logisticsReg/specialDetailsReg`,
    method: 'post',
    data
  })
}

// Jim 物流结算管理

// 对账单分页
export function reconciliationListByPage(params) {
  return request({
    url: `/fin-payment/logisticsAudit/reconciliationListByPage`,
    method: 'get',
    params
  })
}

// 对账登记-发运单选择
export function listShipInfoSelectByPage(params) {
  return request({
    url: `/fin-payment/logisticsAudit/listShipInfoSelectByPage`,
    method: 'get',
    params
  })
}

// 对账登记-等级页面初始化数据获取
export function getReconciliationInfo(data) {
  return request({
    url: `/fin-payment/logisticsAudit/getReconciliationInfo`,
    method: 'post',
    data
  })
}

// 对账登记-对账登记（保存）
export function reconciliationRegistrationSave(data) {
  return request({
    url: `/fin-payment/logisticsAudit/reconciliationRegistrationSave`,
    method: 'post',
    data
  })
}

// 对账登记-对账登记（提交）
export function reconciliationRegistrationSubmit(data) {
  return request({
    url: `/fin-payment/logisticsAudit/reconciliationRegistrationSubmit`,
    method: 'post',
    data
  })
}
// 对账登记-对账登记
export function reconciliationRegistration(data) {
  return request({
    url: `/fin-payment/logisticsAudit/reconciliationRegistration`,
    method: 'post',
    data
  })
}

// 预充值
export function preReChargeAmount(data) {
  return request({
    url: `/fin-payment/logisticsReg/preReChargeAmount`,
    method: 'post',
    data
  })
}

// 承运商变更-针对费用片段
// 物流报价登记
export function reg(data) {
  return request({
    url: `/fin-payment/logisticsReg/reg`,
    method: 'post',
    data
  })
}

// 已对账单分页查询
export function listBilledByPage(params) {
  return request({
    url: `/fin-payment/logisticsAudit/listBilledByPage`,
    method: 'get',
    params
  })
}

// 登记查询-带级联  承运商变更之前
export function listRegForMoreLevelByCondition(params) {
  return request({
    url: `/fin-payment/logisticsReg/listRegForMoreLevelByCondition`,
    method: 'get',
    params
  })
}

// 承运商变更
export function carrierChangeForRegItem(data) {
  return request({
    url: `/fin-payment/logisticsReg/carrierChangeForRegItem`,
    method: 'post',
    data
  })
}

// 模板确认之前调的查shipInfo
export function getShipInfoByCondition(params) {
  return request({
    url: `/fin-payment/logisticsShip/getShipInfoByCondition`,
    method: 'get',
    params
  })
}

// 承运商变更前 检查费用片段是否包含特殊费用
export function checkHasSpecialDetails(regItemId) {
  return request({
    url: `/fin-payment/logisticsReg/checkHasSpecialDetails/${regItemId}`,
    method: 'get'
  })
}

// 进预充值之前的查询
export function listRegItemByCondition(params) {
  return request({
    url: `/fin-payment/logisticsReg/listRegItemByCondition`,
    method: 'get',
    params
  })
}

// 清关登记
export function clearanceReg(data) {
  return request({
    url: `/fin-payment/logisticsReg/clearanceReg`,
    method: 'post',
    data
  })
}

// 发起请款页面 分页 查询
export function listPaymentRequestByPage(params) {
  return request({
    url: `/fin-payment/logisticsAudit/listPaymentRequestByPage`,
    method: 'get',
    params
  })
}
// jim发起请款
export function paymentRequest(data) {
  return request({
    url: `/fin-payment/logisticsAudit/paymentRequest`,
    method: 'post',
    data
  })
}

// 启停状态
export function enableOrDisable(data) {
  return request({
    url: `/fin-payment/logisticsFeeItem/enableOrDisable`,
    method: 'post',
    data
  })
}

// JIm 查看对账单详情
export function getAuditInfoByItemId(params) {
  return request({
    url: `/fin-payment/logisticsAudit/getAuditInfoByItemId`,
    method: 'get',
    params
  })
}

// 校验是否重复清关报价登记
export function checkHasClearanceReg(shipInfoId) {
  return request({
    url: `/fin-payment/logisticsReg/checkHasClearanceReg/${shipInfoId}`,
    method: 'get'
  })
}

// 发起请款页面 校验是有有未完成支付的预充值单
export function checkPaymentAmount(params) {
  return request({
    url: `/fin-payment/logisticsAudit/checkPaymentAmount`,
    method: 'get',
    params
  })
}

// 下载
export function downloadByThoms(params) {
  return request({
    url: `/fin-payment/basic/download`,
    method: 'get',
    params,
    responseType: 'arraybuffer'
  })
}
export function getOffsetInfo(data) {
  return request({
    url: `/fin-payment/requestFunds/getOffsetInfo`,
    method: 'post',
    data
  })
}

// 货柜关联发运单接口
export function listContainerRelateShipInfo(container) {
  return request({
    url: `/fin-payment/logisticsReg/listContainerRelateShipInfo/${container}`,
    method: 'get'
  })
}

// 关联登记
export function relateReg(data) {
  return request({
    url: `/fin-payment/logisticsReg/relateReg`,
    method: 'post',
    data
  })
}

export function carrierBillExport(params) {
  return request({
    url: `/fin-payment/logisticsReg/carrierBillExport`,
    method: 'get',
    params
  })
}
