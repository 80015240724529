import requestWms from '@/utils/request'
export function getcargoownerList() {
  return requestWms({
    url: `/base/cargoowner/list`,
    method: 'get'
  })
}

// 仓库信息查询
export function queryWarehouseList(params) {
  return requestWms({
    url: `/wms-base/basewarehouse/list`,
    method: 'get',
    params
  })
}

export function basewarehouse(params) {
  return requestWms({
    url: `/wms-base/basewarehouse/listAll`,
    method: 'get',
    params
  })
}

// 供应商信息查询
export function queryVendorList() {
  return requestWms({
    url: `/base/basevendor/list`,
    method: 'get'
  })
}

// Style信息查询
export function queryStyleList() {
  return requestWms({
    url: `/base/basestyle/list`,
    method: 'get'
  })
}

export function getVendorAccountListByVendorCode(params) {
  return requestWms({
    url: `base/baseVendorAccount/getVendorAccountListByVendorCode`,
    method: 'get',
    params
  })
}
export function getVendorAccountListByCargoOwnerCode(params) {
  return requestWms({
    url: `base/baseVendorAccount/getVendorAccountListByCargoOwnerCode`,
    method: 'get',
    params
  })
}

// 承运商下拉数据
export function getVendorListByVendorType(params) {
  return requestWms({
    url: `base/basevendor/getVendorListByVendorType`,
    method: 'get',
    params
  })
}
