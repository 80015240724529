/*
 * @Author: your name
 * @Date: 2021-12-22 17:12:35
 * @LastEditTime: 2021-12-22 18:33:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \fin\src\utils\table-columns.js
 */

export const costColumns = [
  { slot: 'estimatePayableNo' },
  { label: '结算主体', prop: 'settleEntity', width: 100 },
  { label: '承运商', prop: 'carrier', width: 170 },
  { label: '来源单号', prop: 'shipNo', width: 180 },
  { label: '运输方式', prop: 'shipMode' },
  { label: '货柜号', prop: 'containerNo' },
  { label: '提单号', prop: 'ladingNo', width: 110 },
  { label: '应付金额', prop: 'payableFees', width: 110 },
  { label: '币种', prop: 'currency' },
  { label: '生成时间', prop: 'createTime', width: 170 },
  { label: '对账状态', prop: 'auditStatusI8' },
  { label: '对账时间', prop: 'auditFinishTime', width: 150 },
  { label: '结算状态', prop: 'settleStatusI18' },
  { slot: 'feeSnippet' },
  { slot: 'download' }
]

export const checkTagColumns = [
  { label: '发运单号', prop: 'shipNo' },
  { label: '预期货柜号', prop: 'expectContainerNo' },
  { label: '运输方式', prop: 'shipMode' },
  { label: '发运仓', prop: 'shipWarehouse' },
  { label: '目的仓', prop: 'destWarehouse' },
  { label: '发运状态', prop: 'shipStatus' },
  { label: '货柜号', prop: 'containerNo' },
  { label: '提单号', prop: 'ladingNo' },
  { label: '铅封号', prop: 'sealNo' },
  { label: '目的港', prop: 'destHarbor' },
  { label: '发运日期', prop: 'shipDate' },
  { slot: 'download' }
]
export const templateColumns = [
  { label: '发运单号', prop: 'shipNo' },
  { label: '货柜号', prop: 'containerNo' },
  { label: '预期货柜号', prop: 'expectContainerNo' }
]
export const associateColumns = [
  { label: '发运单号', prop: 'shipNo' },
  { label: '货柜号', prop: 'containerNo' },
  { label: '发运日期', prop: 'shipDate', width: 180 }
]
export const prepareColumns = [
  { label: '暂估应付单', prop: 'estimatePayableNo' },
  { label: '费用片段', prop: 'feeSnippet' },
  { label: '币种', prop: 'currency' },
  { label: '应付金额', prop: 'payableFees' }
]

export const specialFeeColumns = [
  { label: '费用项目', prop: 'feeItem', width: '120' },
  { label: '金额', prop: 'feeItemFees' },
  { label: '登记人', prop: 'createByName' },
  { label: '登记时间', prop: 'createTime', width: 160 },
  { slot: 'view' }
]

export const detailColumns = [
  { label: 'HS Code ', prop: 'estimationPayableCode', width: '120' },
  { label: '原产国', prop: 'cargoOwnerCode' },
  { label: '数量', prop: 'currency' },
  { label: '单价', prop: 'sourceCode' },
  { label: '总金额', prop: 'cargoOwnerCode' },
  { label: '关税税率', prop: 'currency' },
  { label: '关税税额', prop: 'sourceCode' }
]

export const taxDetailColumns = [
  { label: 'HS Code ', prop: 'estimationPayableCode', width: '120' },
  { label: '申报数量', prop: 'cargoOwnerCode' },
  { label: '申报金额', prop: 'currency' },
  { label: '税率', prop: 'cargoOwnerCode' },
  { label: '税率', prop: 'currency' }, { label: '填报数量', prop: 'cargoOwnerCode' },
  { label: '填报总金额', prop: 'currency' },
  { label: '填报总金额', prop: 'currency' },
  { label: '差异', prop: 'currency' }

]
export const sendOrdersColumns = [
  { label: '发运单号', prop: 'shipNo', width: 180 },
  { label: '结算主体', prop: 'settleEntity', width: 100 },
  { label: '承运商', prop: 'carrier', width: 170 },
  { label: '货柜号', prop: 'containerNo' },
  { label: '提单号', prop: 'ladingNumber' },
  { label: '预期货柜号', prop: 'expectContainerNo' },
  { label: '发运时间', prop: 'shipDate', width: 170 }
]
export const noReconcileColumns = [
  { slot: 'auditNo' },
  { label: '承运商', prop: 'carrier', width: 170 },
  { label: '来源单据', prop: 'sourceNo', width: 180 },
  { label: '结算主体', prop: 'settleEntity', width: 100 },
  { label: '发运单', prop: 'shipNo', width: 180 },
  { label: '货柜号', prop: 'containerNo' },
  { label: '对账状态', prop: 'auditStatusI8' },
  { label: '发运仓', prop: 'shipWarehouse', width: 100 },
  { label: '对账时间', prop: 'auditFinishTime', width: 170 },
  { label: '生成时间', prop: 'createTime', width: 170 },
  { label: '账期截止日期', prop: 'dueDate', width: 150 },
  { label: '单据类型', prop: 'auditTypeI8', width: 100 },
  { label: '结算状态', prop: 'settleStatusI8' },
  { slot: 'operate' },
  { slot: 'download' }
]
export const reconciledColumns = [
  { label: '应付单', prop: 'payableNo', width: 180 },
  { label: '结算主体', prop: 'settleEntity', width: 100 },
  { label: '承运商', prop: 'carrier', width: 170 },
  { label: '来源单据', prop: 'sourceNo', width: 180 },
  { label: '发运单', prop: 'shipNo', width: 180 },
  { label: '币种', prop: 'currency' },
  { slot: 'feeSnippet' },
  { label: '对账确认金额', prop: 'auditConfirmAmount', width: 120 },
  { label: '对账差异', prop: 'differenceFees' },
  { label: '对账时间', prop: 'auditFinishTime', width: 160 },
  { label: '生成时间', prop: 'createTime', width: 170 },
  { label: '账期截止日期', prop: 'dueDate', width: 150 },
  { label: '税率', prop: 'taxRate', width: 90 },
  { label: '已请款金额', prop: 'requestAlreadyAmount', width: 120 },
  { label: '待请款金额', prop: 'requestPendingAmount', width: 120 },
  { label: '待付款金额', prop: 'paymentPendingAmount', width: 120 },
  { label: '请款状态', prop: 'requestAmountStatusI8', width: 120 },
  { label: '请款时间', prop: 'requestAmountTime', width: 160 },
  { label: '付款状态', prop: 'paymentAmountStatusI8' }
]

export const expandReconciledColumns = [
  { label: '请款单', prop: 'requestAmountNo', width: 170 },
  { label: '请款时间', prop: 'requestAmountTime', width: 160 },
  { label: '请款金额', prop: 'requestAmount', width: 110 },
  { label: '请款人', prop: 'requestByName', width: 100 },
  { label: '请款审批状态', prop: 'requestAmountStatusI8', width: 120 },
  { label: '请款审批时间', prop: 'requestAmountFinishTime', width: 160 },
  { label: '付款状态', prop: 'paymentAmountStatusI8', width: 110 },
  { label: '付款时间', prop: 'paymentAmountTime', width: 150 }

]

export const logisticsReconcileColumns = [
  { label: '费用项目', prop: 'feeItem' },
  { label: '填报费用', prop: 'feeItemFees' },
  { label: '特殊费用', prop: 'feeItemSpecialFees' },
  { label: '币种', prop: 'currency' },
  { slot: 'special' },
  { slot: 'tax' },
  { label: '差异', prop: 'differenceFees' }
]

export const toBeOffsetColumns = [
  { label: '应付单', prop: 'payableNo' },
  { label: '费用片段', prop: 'feeSnippet' },
  { label: '来源单据', prop: 'sourceNo' },
  { label: '应付金额', prop: 'auditConfirmAmount' },
  { label: '待冲抵金额', prop: 'requestPendingAmount' },
  { label: '税率', prop: 'taxRate' },
  { label: '币种', prop: 'currency' },
  { slot: 'qkje' },
  { label: '账期截止日期', prop: 'dueDate', width: 150 }
]

export const pendingColumns = [
  { label: '应付单', prop: 'payableNo' },
  { slot: 'feeSnippet' },
  { label: '来源单据', prop: 'sourceNo' },
  { label: '应付金额', prop: 'auditConfirmAmount' },
  { label: '待请款金额', prop: 'requestPendingAmount' },
  { label: '税率', prop: 'taxRate' },
  { label: '币种', prop: 'currency' },
  { slot: 'requestAmount' },
  { label: '账期截止日期', prop: 'dueDate', width: 150 }
]
export const projectManageTableColumns = [
  { label: '承运商', prop: 'carrier', width: 170 },
  { label: '费用片段', prop: 'feeSnippet' },
  { label: '费用类别', prop: 'feeType' },
  { label: '费用项目', prop: 'feeItem' },
  { label: '创建时间', prop: 'createTime', width: 180 },
  { label: '更新时间', prop: 'modifyTime', width: 180 },
  { label: '更新人', prop: 'modifyByName' },
  { slot: 'operate' }
]
export const taxReconcileColumns = [
  { label: '填报税费', prop: 'feeItemFees' },
  { label: '币种', prop: 'currency' },
  { slot: 'special' },
  { label: '差异', prop: 'differenceFees' }
]
export const chooseColumns = [
  { label: '发运单号', prop: 'shipNo', width: 180 },
  { label: '预期货柜号', prop: 'expectContainerNo', width: 140 },
  { label: '运输方式', prop: 'shipMode' },
  { label: '发运仓', prop: 'shipWarehouse' },
  { label: '目的仓', prop: 'destWarehouse' },
  { label: '发运状态', prop: 'ship' },
  { label: '货柜号', prop: 'containerNo' },
  { label: '提单号', prop: 'ladingNo' },
  { label: '铅封号', prop: 'sealNo' },
  { label: '目的港', prop: 'destHarbor' },
  { label: '发运日期', prop: 'shipDate', width: 180 },
  { slot: 'download' }
]

export const numberList = [
  { label: '①', value: 1 },
  { label: '②', value: 2 },
  { label: '③', value: 3 },
  { label: '④', value: 4 },
  { label: '⑤', value: 5 },
  { label: '⑥', value: 6 },
  { label: '⑦', value: 7 },
  { label: '⑧', value: 8 },
  { label: '⑨', value: 9 }
]

export const List = [{
  checkTag: 0,
  feeSnippet: '起运港物流费',
  feeSnippetCode: 'FYPD001',
  feeSnippetGroupNo: 1,
  onePriceTag: 0,
  flag: false,
  numberFlag: false
},
{
  checkTag: 0,
  feeSnippet: '起运港杂费',
  feeSnippetCode: 'FYPD002',
  feeSnippetGroupNo: 2,
  onePriceTag: 0,
  flag: false,
  numberFlag: false

},
{
  checkTag: 0,
  feeSnippet: '报关费用',
  feeSnippetCode: 'FYPD003',
  feeSnippetGroupNo: 3,
  onePriceTag: 0,
  flag: false,
  numberFlag: false

},
{
  checkTag: 0,
  feeSnippet: '干线物流费',
  feeSnippetCode: 'FYPD004',
  feeSnippetGroupNo: 4,
  onePriceTag: 0,
  flag: false,
  numberFlag: false

},
{
  checkTag: 0,
  feeSnippet: '清关费用',
  feeSnippetCode: 'FYPD005',
  feeSnippetGroupNo: 5,
  onePriceTag: 0,
  flag: false,
  numberFlag: false

}, {
  checkTag: 0,
  feeSnippet: '目的港杂费',
  feeSnippetCode: 'FYPD006',
  feeSnippetGroupNo: 6,
  onePriceTag: 0,
  flag: false,
  numberFlag: false

}, {
  checkTag: 0,
  feeSnippet: '二程物流费',
  feeSnippetCode: 'FYPD010',
  feeSnippetGroupNo: 7,
  onePriceTag: 0,
  flag: false,
  numberFlag: false

}, {
  checkTag: 0,
  feeSnippet: '站内转运物流费',
  feeSnippetCode: 'FYPD007',
  feeSnippetGroupNo: 8,
  onePriceTag: 0,
  flag: false,
  numberFlag: false

}, {
  checkTag: 0,
  feeSnippet: '小包直邮费',
  feeSnippetCode: 'FYPD008',
  feeSnippetGroupNo: 9,
  onePriceTag: 0,
  flag: false,
  numberFlag: false

}]

export const FeeList = [
  {
    code: 1,
    label: '承运商1',
    nextSelectionItems: [
      { code: 11,
        label: '起运港物流费',
        nextSelectionItems: [
          { code: 111, label: '空运' },
          { code: 112, label: '海运' },
          { code: 113, label: '铁运' },
          { code: 114, label: '快递' }
        ]
      },
      { code: 12,
        label: '起运港杂费',
        nextSelectionItems: [
          { code: 121, label: '空运' },
          { code: 122, label: '海运' },
          { code: 123, label: '铁运' }
        ]
      },
      { code: 13,
        label: '报关费用',
        nextSelectionItems: [
          { code: 131, label: '空运' },
          { code: 132, label: '海运' }
        ]
      }
    ]
  },
  {
    code: 2,
    label: '承运商2',
    nextSelectionItems: [
      { code: 21,
        label: '起运港物流费',
        nextSelectionItems: [
          { code: 213, label: '卡派' },
          { code: 214, label: '快递' }
        ]
      },
      { code: 22,
        label: '报关费用',
        nextSelectionItems: [
          { code: 221, label: '空运' },
          { code: 222, label: '海运' },
          { code: 223, label: '卡派' }
          // { code: 224, label: '快递' }
        ]
      }
    ]
  },
  {
    code: 3,
    label: '承运商3',
    nextSelectionItems: [
      { code: 31,
        label: '报关费用',
        nextSelectionItems: [
          { code: 311, label: '空运' },
          { code: 312, label: '海运' },
          { code: 313, label: '卡派' },
          { code: 314, label: '快递' }
        ]
      },
      { code: 32,
        label: '目的港杂费',
        nextSelectionItems: [
          { code: 321, label: '空运' },
          { code: 322, label: '海运' },
          { code: 323, label: '快递' }
        ]
      }
    ]
  }
]

