<template>
  <div class="data-table mb-3">
    <el-table
      ref="table"
      v-loading="loading"
      :data="tableData"
      :style="{width:(width?width+'px':'100%')}"
      :header-cell-style="{background:'#fafafa'}"
      :max-height="maxHeight"
      :show-summary="showSummary"
      :summary-method="getSummaries"
      :cell-style="cellStyle"
      :row-key="rowKey"
      :row-class-name="isShowExpend"
      @select="select"
      @select-all="selectAll"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="check"
        type="selection"
        width="30"
        :reserve-selection="reserveSelection"
        :selectable="selectInit"
      />
      <el-table-column
        v-if="singleCheck"
        type="selection"
        width="30"
        :selectable="selectInit"
      >
        <template slot-scope="scope">
          <el-radio v-model="radioId" :label="scope.row.id" :disabled="scope.row.settleStatus + '' === '100'" @change.native="getSelected(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column v-if="expand" type="expand">
        <template slot-scope="props">
          <div class="my_table">
            <el-table :data="props.row.expandData">
              <el-table-column
                label="序号"
                type="index"
                width="50"
                align="center"
              />
              <template v-for="(col, index) in expandColumns">
                <slot v-if="col.slot" :name="col.slot" />
                <el-table-column
                  v-else
                  :key="index"
                  :prop="col.prop"
                  :label="col.label"
                  :width="col.width"
                  :formatter="col.formatter"
                  align="center"
                />
              </template>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="pageObj.current">{{ (pageObj.current - 1)*pageObj.size + scope.$index + 1 }}</span>
          <span v-else>{{ scope.$index+1 }} </span>
        </template>
      </el-table-column>
      <template v-for="(col, index) in columns">
        <!-- 操作列/自定义列 -->
        <slot v-if="col.slot" :name="col.slot" />
        <el-table-column
          v-else
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
          align="center"
        />
      </template>
    </el-table>

  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    cellStyle: {
      type: Function,
      default: () => {
        return {}
      }
    },
    getSummaries: {
      type: Function,
      default: () => {
        return {}
      }
    },
    selectInit: {
      type: Function,
      default: () => {
        return {}
      }
    },
    expand: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    check: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    singleCheck: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    maxHeight: {
      type: String,
      default: () => {
        return '550px'
      }
    },
    reserveSelection: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    showSummary: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    width: {
      type: Number,
      default: () => {
        return 0
      }
    },

    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    },
    expandColumns: {
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    pageObj: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      radioId: ''
    }
  },
  methods: {
    selectAll(selections) {
      this.$emit('selectAll', selections)
    },
    clearSingleCheck() {
      this.radioId = ''
      this.$emit('handleSelectionChange', [])
    },
    getSelected(row) {
      const val = []
      this.radioId = row.id
      val.push(row)
      this.$emit('handleSelectionChange', val)
    },
    select(rows, row) {
      const obj = { rows, row }
      this.$emit('select', obj)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    rowKey(row) {
      return row.defaultKey || row.id
    },
    isShowExpend(row, index) {
      if (row.row.expandData && row.row.expandData.length === 0) {
        return 'hide-expend'
      }
      return ''
    }

  }

}
</script>
<style scoped lang="scss">
/deep/.my_table {
  .el-table {
    td,th{
      border-bottom:none!important;
    }
  }
}
/deep/ .hide-expend .el-table__expand-icon {
  display: none;
}
</style>
